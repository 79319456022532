import React, { useEffect } from "react";
import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";

// image
import mainlogo from "./Assets/images/logo.svg";
import ComingSoon from "./Assets/images/CommingSoon.svg";
import ComingSoontext from "./Assets/images/Comingsoontext.svg";
import bg from "./Assets/images/bg-patter.svg";

function App() {
  useEffect(() => {
    AOS.init({
      // disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);

  return (
    <div className="body-style w-full h-screen flex justify-center items-center">
      <div className="max-h-screen overflow-hidden  gap-24 max-w-[1200px] mx-auto flex justify-center items-center ">
        <div className=" p-8 md:h-[650px] h-[500px] flex justify-center items-center  ">
          <div className="md:flex justify-center items-center gap-0 ">
            <div
              className=" w-full flex md:justify-left justify-center"
              data-aos="flip-up"
              data-aos-delay="400"
            >
              <img
                src={ComingSoon}
                className=" md:w-96 w-44 md:scale-150 scale-110 md:mb-0 mb-12"
              />
            </div>
            <div className="">
              <div
                className="flex flex-col gap-3 items-center justify-center"
                data-aos="flip-up"
                data-aos-delay="400"
              >
                <div className=" text-sm md:text-base text-center text-gray-600">
                  the content open source plaftorm
                </div>
                <div>
                  <img
                    src={ComingSoontext}
                    className=" md:scale-110 scale-75 md:my-2 -my-2"
                  />
                </div>
                <div>
                  <div className=" text-sm md:text-base text-center text-gray-600">
                    Our site is currently under construction
                  </div>
                  <div className=" text-sm md:text-base text-center text-gray-600">
                    We’re working to bring you a brand-new experience. we’ll be
                    launching soon with exciting features, fresh content, and
                    more!
                  </div>
                </div>
                {/* <div>
                <div className=" my-4">Follow us for updates :</div>
                <div className=" flex gap-8 justify-center items-center">
                  <FaTelegram size={44} className=" text-blue-500" />
                  <RiInstagramFill size={44} />
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className=" absolute bottom-0 left-1/2 -translate-x-1/2">
        <div className="spinner-box scale-50">
          <div className="configure-border-1">
            <div className="configure-core"></div>
          </div>
          <div className="configure-border-2">
            <div className="configure-core"></div>
          </div>
        </div>
      </div>

      <img src={bg} className=" fixed top-0 right-0" />
      <img src={bg} className=" fixed -bottom-24 left-0 rotate-180" /> */}
    </div>
  );
}

export default App;
